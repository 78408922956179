.product-border {
	height: 100% !important;
	border-radius: 0 !important;
	border: 5px solid var(--secondary-mute-color) !important;
}

.product-border:hover {
	border: 5px solid rgba(86, 146, 208, 0.5) !important;
}

.new-border {
	border-bottom: 1px solid var(--primary-color) !important;
	border-top: 1px solid var(--primary-color) !important;
}

.youtube-icon {
	color: black;
	opacity: 0.5;
}

.youtube-parent:hover .youtube-icon {
	color: red;
	opacity: 1;
}

.online-sale-button {
	background-color: var(--secondary-color) !important;
	color: var(--white) !important;
	border: 1px solid var(--primary-color) !important;
	font-weight: 600;
	font-size: 0.75rem !important;
	width: 10rem;
}

.online-sale-button:hover {
	background-color: var(--primary-color) !important;
}

.combo-overflow {
	max-height: 15rem;
	min-height: 15rem;
	/* overflow-y: scroll; */
	overflow-x: hidden;
}

.enterprise-intro-title {
	color: var(--primary-color);
	font-size: 43px;
	font-weight: bold;
	text-transform: uppercase;
}

.enterprise-intro-text {
	color: var(--black);
	font-size: 1.5rem;
	font-weight: 200;
	line-height: 35px;
}

.enterprise-intro-button {
	color: var(--white) !important;
	background-color: var(--primary-color) !important;
	border-radius: 8px !important;
	border: 1px solid var(--primary-color) !important;
	font-weight: bold !important;
	font-size: 1.5rem !important;
	padding: 0.5rem 2rem !important;
}

.enterprise-intro-button:hover {
	background-color: var(--secondary-color) !important;
}

.enterprise-intro-mute-button {
	color: var(--primary-color) !important;
	background-color: var(--white) !important;
	border-radius: 8px !important;
	border: 3px solid var(--primary-color) !important;
	font-weight: bold !important;
	font-size: 1.5rem !important;
	padding: 0.5rem 3rem !important;
}

.enterprise-title {
	color: var(--black);
	font-size: 2rem;
	font-weight: bold;
	text-transform: uppercase;
}

.enterprise-advan-item {
	width: 20%;
}

.enterprise-feature-img-bg {
	aspect-ratio: 1921/1011;
	background-size: cover;
}

.enterprise-feature-icon {
	height: 3rem;
	width: 3rem;
}

.enterprise-feature-hover {
	cursor: pointer;
}

.enterprise-feature-circle {
	font-weight: bold;
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.enterprise-feature-title {
	text-decoration: none;
	color: var(--black);
	font-size: 1rem;
	font-weight: bold;
}

.enterprise-feature-hover:hover .enterprise-feature-circle {
	background-color: var(--primary-color);
	color: var(--white);
	border: 1px solid var(--primary-color);
}

.enterprise-feature-hover:hover .enterprise-feature-title {
	color: var(--primary-color);
}

.enterprise-botton-img-bg {
	aspect-ratio: 1145/178;
	background-size: cover;
}

@media only screen and (max-width: 1244px) {
	.enterprise-feature-img-bg {
		aspect-ratio: 1/0.8;
		background-size: cover;
		background-position: right;
	}

	.enterprise-botton-img-bg {
		aspect-ratio: 1145/200;
		background-size: cover;
		background-position: right;
	}

	.enterprise-feature-icon {
		height: 2rem;
		width: 2rem;
	}
}

@media only screen and (max-width: 768px) {
	.online-sale-button {
		border: 1px solid var(--primary-color) !important;
		font-weight: 600;
		font-size: 0.5rem !important;
		width: 7rem;
	}

	.combo-overflow {
		max-height: 10rem;
		min-height: 10rem;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.enterprise-intro-title {
		color: var(--primary-color);
		font-size: 2rem;
		font-weight: bold;
		text-transform: uppercase;
	}

	.enterprise-intro-text {
		color: var(--black);
		font-size: 1rem;
		font-weight: 200;
		line-height: 24px;
	}

	.enterprise-intro-button {
		font-size: 1rem !important;
		padding: 0.3rem 1.5rem !important;
	}

	.enterprise-intro-mute-button {
		font-size: 1rem !important;
		padding: 0.3rem 2rem !important;
	}

	.enterprise-advan-item {
		width: 100%;
	}

	.enterprise-feature-img-bg {
		aspect-ratio: 1/2;
		background-size: cover;
	}
}
