.inter-title {
	font-size: 3rem;
}

.inter-circle {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: #d3676c;
}

.internet-image-bg {
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	aspect-ratio: 1440/562;
}

.combo-image-bg {
	height: 45rem;
}

.inter-detail-icon {
	height: 2.8rem;
	width: 2.8rem;
}

.inter-detail-title {
	font-size: 1.5rem;
}

.inter-detail-sub {
	font-size: 0.75rem;
}

@media only screen and (max-width: 992px) {
	.inter-title {
		font-size: 2rem;
	}

	.internet-image-bg {
		width: 100%;
		height: 20rem;
		background-size: cover;
	}
}

@media only screen and (max-width: 768px) {
	.inter-title {
		font-size: 1.5rem;
	}

	.inter-circle {
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: #d3676c;
	}

	.internet-image-bg {
		width: 100%;
		height: 20rem;
		background-size: cover;
	}

	.combo-image-bg {
		height: 35rem;
	}

	.inter-detail-icon {
		height: 2rem;
		width: 2rem;
	}

	.inter-detail-title {
		font-size: 1rem;
	}
}
