.main-package-card {
	border-radius: 16px !important;
	border-bottom-left-radius: 0 !important;

	border: 1px solid var(--bs-border-color-translucent) !important;
	border-bottom: 0.5rem solid var(--primary-color) !important;
}

.main-package-card:hover {
	border: 1px solid var(--primary-color) !important;
	border-bottom: 0.5rem solid var(--primary-color) !important;
}

.data-package-detail-card {
	background-color: #dee6ee;
	border-radius: 16px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.data-package-detail-card::before {
	width: 100%;
	aspect-ratio: 735/68;
	background-image: url("https://vietteltelecom.vn/images_content/bg-endow.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
