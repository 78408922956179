.image-default {
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.display-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Button */
/* .viettel-button {
	color: var(--white) !important;

	background-color: transparent !important;

	border: 1px solid var(--primary-color) !important;
	border-radius: 8px !important;
	border-bottom-left-radius: 0 !important;
	font-weight: 600 !important;
	position: relative;
	transition: all 0.3s;
}

.viettel-button::after {
	background: var(--primary-color) !important;
}

.viettel-button::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--secondary-color);
	border: 1px solid var(--secondary-color);
	border-radius: 5px;
	border-bottom-left-radius: 0;
	z-index: -1;
	transition: transform 0.3s;
	transform-origin: 0 0;
	transform: scaleX(0);
}

.viettel-button:hover::before {
	transform: scaleX(1) !important;
} */

.primary-button {
	color: var(--white) !important;
	background-color: var(--primary-color) !important;
	border-radius: 8px !important;
	border-bottom-left-radius: 0 !important;
	border: none !important;
	font-weight: 600 !important;
}

.primary-button:hover {
	background-color: var(--secondary-color) !important;
}

.mute-button {
	color: var(--black) !important;
	background: none !important;

	border: 1px solid #979797 !important;
	border-radius: 8px !important;
	border-bottom-left-radius: 0 !important;

	font-weight: 600 !important;

	position: relative;
	transition: all 0.3s;
}

.mute-button:hover {
	color: var(--white) !important;
	background: #979797 !important;
}

.white-button {
	color: var(--primary-color) !important;
	background-color: var(--white) !important;
	border-radius: 8px !important;
	border-bottom-left-radius: 0 !important;
	border: none !important;
	font-weight: 600 !important;
}

.white-button:hover {
	background-color: var(--secondary-color) !important;
	color: var(--white-color) !important;
}
/* Button */

/* Radius */
.radius-8 {
	border-radius: 8px !important;
}

.normal-border-16 {
	border-radius: 16px !important;
}

.top-radius-8 {
	border-radius: 8px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.viettel-radius {
	border-radius: 16px !important;
	border-bottom-left-radius: 0 !important;
}
/* Radius */

/* Text Overflow */
.text-overflow-1-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.text-overflow-2-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.text-overflow-3-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.text-overflow-4-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}
/* Text Overflow */

/* Link */
.link {
	text-decoration: none !important;
	color: var(--primary-color);
}

.underline-link {
	text-decoration: none;
	color: var(--primary-color);
	font-weight: 600;
}

.underline-link:hover {
	text-decoration: underline;
	color: var(--secondary-color);
}
/* Link */

/* Tab */
.section-tab {
	font-size: 1rem;
	color: #576c8a;
	line-height: 19px;
	border-bottom: 2px solid #576c8a;
	padding: 0rem 0.5rem;
	cursor: pointer;
	font-weight: normal;
	width: fit-content;
}

.section-tab:hover {
	color: var(--primary-color);
	border-bottom: 2px solid var(--primary-color);
}

.section-tab-active {
	font-size: 1rem;
	color: var(--primary-color);
	line-height: 19px;
	border-bottom: 2px solid var(--primary-color);
	padding: 0rem 0.5rem;
	cursor: pointer;
	font-weight: normal;
	width: fit-content;
}
/* Tab */

/* Text */
.section-title {
	text-decoration: none !important;
	color: #555555;
	font-size: 2rem;
	font-weight: 500;
	text-transform: uppercase;
	font-style: normal;
}

.item-title-hover {
	text-decoration: none !important;
	color: var(--black);
	font-size: 1.5rem;
	font-weight: bold !important;
	font-style: normal;
}

.item-title-hover:hover {
	color: var(--primary-color) !important;
}

.item-title {
	text-decoration: none !important;
	color: var(--black);
	font-size: 1.5rem;
	font-weight: bold !important;
	font-style: normal;
}

.item-text {
	text-decoration: none !important;
	color: var(--black);
	font-size: 1rem;
	font-style: normal;
}

@media only screen and (max-width: 768px) {
	.section-title {
		text-decoration: none !important;
		color: #555555;
		font-size: 1.5rem;
		font-weight: 500;
		text-transform: uppercase;
		font-style: normal;
	}

	.item-title {
		font-size: 1.2rem;
	}

	.item-text {
		font-size: 0.8rem;
	}
}
/* Text */
