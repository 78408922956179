.enterprise-card {
	border-radius: 16px !important;
	border: 1px solid var(--bs-border-color-translucent) !important;
	border-top: 0.4rem solid var(--primary-color) !important;
}

.enterprise-bg-image {
	aspect-ratio: 1200/250;
}

.tab-text-width {
	width: 50%;
}

@media only screen and (max-width: 768px) {
	.enterprise-bg-image {
		aspect-ratio: 2/1;
		background-size: cover;
		background-position: left;
	}

	.tab-text-width {
		width: 100%;
	}
}
