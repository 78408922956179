:root {
	--nav-height: 4rem;
	--primary-color: #ee0033;
	--secondary-color: #c2002a;

	--white: white;
	--black: black;

	--mute-bg-color: #e7e7e7;
	--mute-text-color: #576c8a;
}

.dropdown-menu {
	--bs-dropdown-link-active-bg: var(--primary-color) !important;
	margin-top: -0.1rem !important;
}

.dropdown-toggle::after {
	display: none !important;
}

.my-input {
	width: 100%;
	height: 50px;
	border: 1px solid #bdbdbd;
	border-radius: 5px;
	padding: 5px 0 5px 10px;
	font-family: "Sarabun";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
	display: flex;
	justify-content: center;
}

/* Boostrap Pagination */
.page-item .page-link {
	color: var(--primary-color);
}

.page-item .page-link:hover {
	color: var(--primary-color);
}

.page-item.active .page-link {
	background: var(--primary-color);
	border: 1px solid var(--primary-color);
}

.page-item.active .page-link:hover {
	color: white;
}
/* Boostrap Pagination */

/* Header */
.header {
	background-color: var(--mute-bg-color);
	display: block !important;
}

.header-text {
	text-decoration: none;
	color: var(--mute-text-color);
	font-size: 1rem;
}

.header-text:hover {
	color: var(--primary-color);
}
/* Header */

/* Navbar */
.nav-height {
	height: var(--nav-height) !important;
	background-color: var(--white) !important;
	z-index: 1052 !important;
	position: sticky !important;
	top: 53.6px !important;
	/* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
}

.nav-text {
	text-decoration: none;
	color: var(--mute-color) !important;
	font-size: 1rem;
}

.nav-text:hover {
	color: var(--primary-color) !important;
}

.desktop-nav {
	display: flex !important;
}

.mobile-nav {
	display: none !important;
}

.toggle-button {
	font-size: 1.5rem;
	cursor: pointer;
}
/* Navbar */

/* Sidebar */
.sidebar-menu {
	display: none;
}

.curtain {
	display: none;
}

.show-on-desktop {
	display: block;
}

.show-on-mobile {
	display: none;
}

.sidebar-text {
	font-weight: 600 !important;
	color: var(--black) !important;
	font-size: 0.9rem !important;
	text-decoration: none;
	cursor: pointer;
}

.sidebar-sub-text {
	color: var(--black) !important;
	font-size: 0.9rem !important;
	text-decoration: none;
	cursor: pointer;
}

/* Sidebar */

/* Footer */
.footer-extra {
	background-color: var(--secondary-color);
	color: var(--white);
	font-size: 0.8rem;
}

.footer-main {
	background-color: var(--mute-bg-color);
}

.footer-text {
	color: var(--black);
	font-size: 0.8rem;
	font-weight: 600;
}

.footer-title {
	color: var(--black);
	font-size: 1rem;
	font-weight: 600;
}

.footer-link {
	text-decoration: none;
	color: var(--black);
	font-size: 0.8rem;
}

.footer-link:hover {
	color: var(--primary-color);
}
/* Footer */

.hover-border {
	border: 1px solid var(--bs-border-color-translucent) !important;
}

.hover-border:hover {
	border: 1px solid var(--primary-color) !important;
}

/* Breadcrumb */
.breadcrumb-item + .breadcrumb-item::before {
	content: var(--bs-breadcrumb-divider, ">") !important ;
}

.my-breadcrumb > a {
	color: var(--black);
	text-decoration: none !important;
	font-size: 1.2rem;
	font-weight: 600;
}

.my-breadcrumb1:hover > a {
	color: var(--primary-color) !important;
}

.my-breadcrumb1 > a {
	color: var(--white);
	text-decoration: none !important;
	font-size: 1.2rem;
	font-weight: 600;
}

.my-breadcrumb:hover > a {
	color: var(--primary-color) !important;
}

.my-breadcrumb-active > a {
	color: var(--primary-color) !important;
	text-decoration: none !important;
	font-weight: bold !important;
	font-size: 1.2rem;
}
/* Breadcrumb */
@media (max-width: 992px) {
	.nav-height {
		top: 0 !important;
	}

	.header {
		display: none !important;
	}

	.desktop-nav {
		display: none !important;
	}

	.mobile-nav {
		display: flex !important;
	}

	.sidebar-menu {
		background-color: white;
		width: 18rem;
		height: 100vh;
		justify-content: flex-start;
		position: fixed;
		top: 4rem;
		left: -100%;
		transition: 850ms;
		z-index: 1051;
		display: block;
	}

	.sidebar-menu.active {
		left: 0;
		transition: 350ms;
	}

	.curtain {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		background-color: black;
		transition: 850ms;
		z-index: 1050;
		display: none;
	}

	.curtain.active {
		opacity: 0.5;
		transition: 350ms;
		display: block;
	}

	.show-on-desktop {
		display: none;
	}

	.show-on-mobile {
		display: block;
	}
}

.data-package-height {
	min-height: 3rem;
	max-height: 3rem;
}

.main-package-height {
	min-height: 6rem;
	max-height: 6rem;
}

@media only screen and (max-width: 768px) {
	.data-package-height {
		min-height: 2.5rem;
		max-height: 2.5rem;
	}

	.main-package-height {
		min-height: 4.5rem;
		max-height: 4.5rem;
	}
}
